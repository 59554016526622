import { Routes } from '@angular/router';
var ɵ0 = function () { return import("./employer-details/employer-details.module.ngfactory").then(function (m) { return m.EmployerDetailsModuleNgFactory; }); }, ɵ1 = function () { return import("./employer-details/employer-details-health/health.module.ngfactory").then(function (m) { return m.HealthModuleNgFactory; }); }, ɵ2 = function () { return import("./employer-details/employer-details-dental/dental.module.ngfactory").then(function (m) { return m.DentalModuleNgFactory; }); };
var routes = [
    {
        path: '',
        redirectTo: 'employer-details',
        pathMatch: 'full'
    },
    {
        path: 'employer-details',
        loadChildren: ɵ0
    },
    {
        path: 'employer-details/health',
        loadChildren: ɵ1
    },
    {
        path: 'employer-details/dental',
        loadChildren: ɵ2
    },
];
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1, ɵ2 };
