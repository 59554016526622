<div class="footer">
  <div class="container">
    <div class="row">
      <div class="col-6">
        <i class="far fa-copyright"></i> 2017-2019 Massachusetts Health
        Connector. All Rights Reserved.
      </div>
      <div class="col-6">
        <ul class="list-unstyled list-inline float-right">
          <li>
            <i class="fas fa-envelope"></i> smallbusiness@mahealthconnector.org
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
