/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "./header.component";
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
export function View_HeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "nav", [["class", "main-header navbar navbar-expand-lg"]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.NgbNavbar, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [["class", "navbar-brand pt-3 pb-3 pr-3"], ["href", "#"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", "MHC Site logo"], ["height", "40"], ["src", "assets/images/mhc_logo.svg"], ["width", "126px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "collapse navbar-collapse"], ["id", "navbarNavDropdown"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "ul", [["class", "navbar-nav"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "li", [["class", "nav-item active"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "a", [["class", "nav-link main-link"], ["href", "#"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Employer Quick Quote"]))], null, null); }
export function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵdid(1, 49152, null, 0, i3.HeaderComponent, [], null, null)], null, null); }
var HeaderComponentNgFactory = i1.ɵccf("app-header", i3.HeaderComponent, View_HeaderComponent_Host_0, {}, {}, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
