var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
function padNumber(value) {
    if (isNumber(value)) {
        return ("0" + value).slice(-2);
    }
    else {
        return '';
    }
}
function isNumber(value) {
    return !isNaN(toInteger(value));
}
function toInteger(value) {
    return parseInt("" + value, 10);
}
function toYear(value) {
    var year_digits = value.substring(0, 4).length;
    var birth_year = toInteger(value);
    var current_year = new Date().getFullYear();
    if (year_digits === 4 && birth_year > current_year - 120 && birth_year < current_year + 1) {
        return birth_year;
    }
    else {
        return null;
    }
}
var CustomDateParserFormatter = /** @class */ (function (_super) {
    __extends(CustomDateParserFormatter, _super);
    function CustomDateParserFormatter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CustomDateParserFormatter.prototype.parse = function (value) {
        if (value) {
            var dateParts = value.replace(/\s/g, '').trim().split('/');
            if (dateParts.length === 1 && isNumber(dateParts[0])) {
                return { year: null, month: toInteger(dateParts[0]), day: null };
            }
            else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
                return { year: null, month: toInteger(dateParts[0]), day: toInteger(dateParts[1]) };
            }
            else if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
                return { year: toYear(dateParts[2]), month: toInteger(dateParts[0]), day: toInteger(dateParts[1]) };
            }
        }
        return null;
    };
    CustomDateParserFormatter.prototype.format = function (date) {
        var stringDate = '';
        if (date) {
            stringDate += isNumber(date.month) ? padNumber(date.month) + '/' : '';
            stringDate += isNumber(date.day) ? padNumber(date.day) + '/' : '';
            stringDate += date.year;
        }
        return stringDate;
    };
    return CustomDateParserFormatter;
}(NgbDateParserFormatter));
export { CustomDateParserFormatter };
